var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('p', [_c('strong', [_vm._v("Info: ")]), _c('span', [_vm._v("Please check the ")]), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "href": "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/guide/layout/page-layout-examples.html#boxed-content",
      "target": "_blank"
    }
  }, [_vm._v("Boxed Layout documentation")]), _c('span', [_vm._v(" for more details.")])], 1)])]), _c('ecommerce')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }